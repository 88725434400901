<template>
 <div class="inner-section mt-3">
    <bread-cumb />
       <body-card>
       <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.prepare_schedule_title') }} {{ $route.params.id ? $t('globalTrans.modify') : $t('globalTrans.entry') }}</h4>
      </template>
          <b-container fluid>
              <b-row>
              <b-overlay :show="loading">
                <b-col sm="12" class="mt-3">
                  <slot>
                      <!-- =================Main application===================== -->
                    <div >
                      <ValidationObserver ref="prepare_schedule_form" v-slot="{ handleSubmit, reset }">
                        <!-- <b-overlay :show="loading"> -->
                          <b-form @submit.prevent="handleSubmit(cloneAddItem)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                            <b-row>
                              <b-col xs="12" sm="12" md="4">
                                <ValidationProvider name="fiscal_year_id" vid="fiscal_year_id" rules="required|min_value:1">
                                  <b-form-group
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="clone_info_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                              <ValidationProvider name="Schedule Name (En)" vid="schedule_name_en" rules="required">
                                  <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="schedule_name_en">
                                  <template v-slot:label>
                                      {{ $t('teaGardenBtriService.schedule_name') }} {{ $t('globalTrans.en') }}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="schedule_name_en"
                                      v-model="data.schedule_name_en"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                              </b-col>
                             <b-col xs="12" sm="12" md="4">
                              <ValidationProvider name="Schedule Name (Bn)" vid="schedule_name_bn" rules="required">
                                  <b-form-group
                                  slot-scope="{ valid, errors }"
                                  label-for="schedule_name_bn">
                                  <template v-slot:label>
                                      {{ $t('teaGardenBtriService.schedule_name') }} {{ $t('globalTrans.bn') }}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="schedule_name_bn"
                                      v-model="data.schedule_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                  <ValidationProvider name="From Date" vid="from_date" rules="required">
                                    <b-form-group
                                        label-for="from_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('globalTrans.from_date')}} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date"
                                        v-model="data.from_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-col xs="12" sm="12" md="4">
                                  <ValidationProvider name="To Date" vid="to_date" rules="required">
                                    <b-form-group
                                        label-for="to_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('globalTrans.to_date')}} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="date"
                                        v-model="data.to_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-form>
                        <!-- </b-overlay> -->
                      </ValidationObserver>
                    </div>
                    <!-- =================Add More Start===================== -->
                    <body-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('teaGardenBtriService.prepare_schedule_title') }}</h4>
                      </template>
                      <template v-slot:body>
                        <b-row>
                          <ValidationObserver ref="details_item" v-slot="{ handleDetailsItem, reset }">
                            <b-form @submit.prevent="handleDetailsItem(saveUpdate)" @reset.prevent="reset">
                              <b-col xs="12" sm="12" md="4" class="pl-0">
                                  <ValidationProvider name="Tentative Date" vid="tentative_date" rules="required">
                                    <b-form-group
                                        label-for="tentative_date"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                                      </template>
                                      <date-picker
                                        id="tentative_date"
                                        v-model="details.tentative_date"
                                        class="form-control"
                                        :placeholder="$t('globalTrans.select_date')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        :class="errors[0] ? 'is-invalid' : ''"
                                      >
                                      </date-picker>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                              </b-col>
                              <b-row>
                                <b-col xs="12" sm="12" md="6">
                                <!-- add more garden -->
                                  <b-row>
                                    <b-col xs="12" sm="12" md="7">
                                      <ValidationProvider name="Garden Name" vid="garden_id">
                                        <b-form-group
                                          label-for="garden_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{$t('teaGardenConfig.garden_name')}}
                                        </template>
                                        <v-select
                                          id="garden_id"
                                          v-model="garden_infos.garden_id"
                                          :reduce="op => op.value"
                                          :options="gardenList"
                                          label="text"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('globalTrans.select')"
                                          >
                                          </v-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="5" class="mt-4">
                                          <b-button type="button" @click="addMoreGardenInfo" variant="success" class="mr-2 btn-sm">
                                            <span v-if="loading">
                                                <b-spinner small label="Loading..."></b-spinner>
                                            </span>
                                            <span v-else>
                                                <i class="fas fa-plus-circle m-0"></i>
                                            </span>
                                            {{details.garden_infos && details.garden_infos.length > 0 ? $t('stock_management.add_more') : $t('globalTrans.add')}}
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                  <!-- GARDEN INFO TABLE SHOW -->
                                  <div class="table-wrapper table-responsive">
                                    <table class="table table-striped table-hover table-bordered">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                                    <th scope="col" class="text-center"><div>{{ $t('teaGardenConfig.garden_name') }}</div></th>
                                                    <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-if="details.garden_infos && details.garden_infos.length > 0">
                                                    <tr v-for="(item, index) in details.garden_infos" :key="index">
                                                        <td  class="text-center">{{ $n(index+1) }}</td>
                                                        <td class="text-center">{{ getGardenName(item.garden_id) }}</td>
                                                        <td class="text-center">
                                                            <b-button v-if="!item.id" variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="addMoreGardenInfoRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td colspan="3" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                  </div>
                                </b-col>
                                <b-col xs="12" sm="12" md="6">
                                  <!-- add more assing user -->
                                  <b-row>
                                    <b-col xs="12" sm="12" md="7">
                                      <ValidationProvider name="Service Name" vid="service_id">
                                        <b-form-group
                                          label-for="service_id"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{$t('teaGardenBtriService.assign_person')}}
                                        </template>
                                        <v-select
                                          id="service_id"
                                          v-model="assign_users.user_id"
                                          :reduce="op => op.user_id"
                                          :options="userList"
                                          label="text"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('globalTrans.select')"
                                          >
                                          </v-select>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="5" class="mt-4">
                                          <b-button type="button" @click="addMoreAssignUserInfo" variant="success" class="mr-2 btn-sm">
                                            <span v-if="loading">
                                                <b-spinner small label="Loading..."></b-spinner>
                                            </span>
                                            <span v-else>
                                                <i class="fas fa-plus-circle m-0"></i>
                                            </span>
                                            {{details.assign_users && details.assign_users.length > 0 ? $t('stock_management.add_more') : $t('globalTrans.add')}}
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                  <!-- Assign user INFO TABLE SHOW -->
                                  <div class="table-wrapper table-responsive">
                                    <table class="table table-striped table-hover table-bordered">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                                    <th scope="col" class="text-center"><div>{{ $t('teaGardenBtriService.assign_person') }}</div></th>
                                                    <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-if="details.assign_users && details.assign_users.length > 0">
                                                    <tr v-for="(item, index) in details.assign_users" :key="index">
                                                        <td  class="text-center">{{ $n(index+1) }}</td>
                                                        <td class="text-center">{{ getUserName(item.user_id) }}</td>
                                                        <td class="text-center">
                                                            <b-button v-if="!item.id" variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="addMoreAssignUserInfoRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-else>
                                                    <tr>
                                                        <td colspan="3" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-form>
                          </ValidationObserver>
                        </b-row>
                      </template>
                    </body-card>
                    <br/>
                    <div class="d-flex justify-content-end">
                      <b-button class="btn-sm mb-2" type="button" @click="addMoreDetailsInfo" variant="success">
                        <span v-if="loading">
                            <b-spinner small label="Loading..."></b-spinner>
                        </span>
                        <span v-else>
                            <i class="fas fa-plus-circle m-0"></i>
                        </span>
                        {{data.details && data.details.length > 0 ? $t('teaGardenBtriService.add_more_schedule') : $t('teaGardenBtriService.add_schedule')}}
                    </b-button>
                    </div>
                    <!-- schedule details -->
                      <div class="table-wrapper table-responsive">
                      <ValidationObserver ref="prepare_schedule_form_details">
                        <table class="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr class="bg-primary">
                                        <th scope="col" style="width: 10%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('globalTrans.date') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('teaGardenConfig.garden_name') }}</div></th>
                                        <th scope="col" class="text-center"><div>{{ $t('teaGardenBtriService.assign_person') }}</div></th>
                                        <th style="width: 10%"><div>{{ $t('globalTrans.action') }}</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="data.details && data.details.length > 0">
                                        <tr v-for="(item, index) in data.details" :key="index">
                                            <td  class="text-center">{{ $n(index+1) }}</td>
                                            <td class="text-center">{{ item.tentative_date | dateFormat }}</td>
                                            <td style="width:30%" class="text-center">
                                              <table style="width: 100%">
                                                <tr v-for="(gardenItem, index) in item.garden_infos" :key="'garden-row-' + index">
                                                <ValidationProvider name="Garden Name" :vid="`garden_id-${index}`" :rules="{ required: true, min_value :1 }">
                                                    <b-form-group
                                                      :label-for="`garden_id-${index}`"
                                                      slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-select
                                                        plain
                                                        :id="`garden_id-${index}`"
                                                        v-model="gardenItem.garden_id"
                                                        :options="gardenList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </b-form-group>
                                                  </ValidationProvider>
                                                </tr>
                                              </table>
                                            </td>
                                            <td style="width: 30%" class="text-center">
                                              <table style="width: 100%">
                                                <tr v-for="(userItem, index) in item.assign_users" :key="'garden-row-' + index">
                                                <ValidationProvider name="User Name" :vid="`user_id-${index}`" :rules="{ required: true, min_value :1 }">
                                                    <b-form-group
                                                      :label-for="`user_id-${index}`"
                                                      slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-select
                                                        plain
                                                        :id="`user_id-${index}`"
                                                        v-model="userItem.user_id"
                                                        :options="userList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                      >
                                                        <template v-slot:first>
                                                          <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                      </b-form-select>
                                                      <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                      </div>
                                                    </b-form-group>
                                                  </ValidationProvider>
                                                </tr>
                                              </table>
                                            </td>
                                            <td class="text-center">
                                              <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="addMoreDetailsInfoRemove(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                      <tr>
                                        <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                                      </tr>
                                    </template>
                                </tbody>
                        </table>
                      </ValidationObserver>
                      </div>
                    <!-- =================Add More End===================== -->
                    <br>
                    <b-row>
                      <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="Note (En)" vid="note_en" :rules="{required: false}">
                          <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="note_en">
                          <template v-slot:label>
                              {{ $t('globalTrans.note') }} {{ $t('globalTrans.en') }}
                          </template>
                          <b-form-input
                              id="note_en"
                              v-model="data.note_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                      </b-col>
                      <b-col xs="12" sm="12" md="4">
                      <ValidationProvider name="Note (Bn)" vid="note_bn" :rules="{required: false}">
                          <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="note_bn">
                          <template v-slot:label>
                            {{ $t('globalTrans.note') }} {{ $t('globalTrans.bn') }}
                          </template>
                          <b-form-input
                              id="note_bn"
                              v-model="data.note_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                      </b-col>
                    </b-row>
                </slot>
                <b-row class="text-right mb-3">
                  <b-col>
                    <b-button type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                    <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back">{{ $t('globalTrans.cancel') }}</b-button>
                  </b-col>
                </b-row>
                </b-col>
                </b-overlay>
              </b-row>
          </b-container>
     </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { regularGarInsStore, regularGarInsUpdate } from '../../../api/routes'
import BreadCumb from '../../../../../../components/BreadCumb.vue'
export default {
  name: 'Form',
  props: ['id'],
  components: { BreadCumb },
  data () {
    return {
      valid: null,
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        details: []
      },
      garden_infos: {},
      assign_users: {},
      userList: [],
      details: {
        tentative_date: '',
        garden_infos: [],
        assign_users: []
      }
    }
  },
  created () {
    this.getUserList()
    if (this.$route.params.id) {
      const tmp = this.getEditEntry()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.localizeUserList(newVal)
      }
    }
  },
  methods: {
    back () {
      this.$router.push({ name: 'tea_garden_service.pdu.prepare_schedule_list' })
    },
    // ================Garden Info Add=================
    addMoreGardenInfo () {
      if (this.garden_infos.garden_id) {
        const exist = this.details.garden_infos.find(gardenItem => gardenItem.garden_id === this.garden_infos.garden_id)
        if (!exist) {
          this.details.garden_infos.push(this.garden_infos)
          this.garden_infos = {
            garden_id: ''
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: 'red'
        })
        }
      }
    },
    addMoreGardenInfoRemove (index) {
      this.details.garden_infos.splice(index, 1)
    },
    // ================User Assing Info Add=================
    addMoreAssignUserInfo () {
      if (this.assign_users.user_id) {
        const exist = this.details.assign_users.find(userItem => userItem.user_id === this.assign_users.user_id)
        if (!exist) {
          this.details.assign_users.push(this.assign_users)
          this.assign_users = {
            user_id: ''
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: 'red'
        })
        }
      }
    },
    addMoreAssignUserInfoRemove (index) {
      this.details.assign_users.splice(index, 1)
    },
    addMoreDetailsInfo () {
      if (this.details.tentative_date && this.details.garden_infos.length > 0 && this.details.assign_users.length > 0) {
        const exist = this.data.details.find(detailItem => detailItem.tentative_date === this.details.tentative_date)
        if (!exist) {
          this.data.details.push(this.details)
          this.details = {
            tentative_date: '',
            garden_infos: [],
            assign_users: []
          }
        } else {
          this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.data_exists'),
          color: 'red'
        })
        }
      } else {
        this.$toast.success({
          title: 'Warning',
          message: this.$t('globalTrans.invalidEntry'),
          color: 'red'
        })
      }
    },
    addMoreDetailsInfoRemove (index) {
      this.data.details.splice(index, 1)
    },
    async saveUpdate (status = 0) {
    const isValid = await this.$refs.prepare_schedule_form.validate()
    const schedueDetailsValid = await this.$refs.prepare_schedule_form_details.validate()
    if (this.data.details.length === 0) {
        this.$toast.success({
          title: 'Warning',
          message: this.$t('teaGardenBtriService.schedule_empty'),
          color: 'red'
        })
    }
      this.data.status = status
      if (isValid && schedueDetailsValid && this.data.details.length > 0) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.params.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${regularGarInsUpdate}/${this.$route.params.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, regularGarInsStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.$route.params.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push({ name: 'tea_garden_service.pdu.prepare_schedule_list' })
      } else {
        this.$refs.mainForm.setErrors(result.errors)
      }
    }
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getUserName (id) {
      const obj = this.userList.find(item => item.user_id === id)
      if (obj) {
        return this.currentLocale === 'en' ? obj.name : obj.name_bn
      }
    },
    async getUserList () {
      const btriOfficeId = this.$store.state.CommonService.commonObj.officeList.find(item => item.text_en.toLowerCase() === 'project development unit (pdu)').value
      this.userLoading = true
      const params = {
        office_id: btriOfficeId,
        org_id: 5
      }
      await RestApi.getData(authServiceBaseUrl, '/user-management/user/all-user-by-org', params).then(response => {
        const userList = response.map((obj, index) => {
            return Object.assign({}, obj, {
              text: this.currentLocale === 'en' ? obj.name : obj.name_bn, value: obj.user_id
            })
          })
          this.userList = userList.filter(item => item !== undefined)
      })
    },
    localizeUserList (locale) {
      const tmp = this.userList.map(item => {
        return Object.assign({}, item, {
          text: locale === 'en' ? item.name : item.name_bn
        })
    })
      this.userList = tmp
    },
    getEditEntry () {
      const itemId = this.$route.params.id
      const tmpData = this.$store.state.list.find(item => item.id === parseInt(itemId))
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
